// src/config/routeConfig.js

export const PROTECTED_ROUTES = [
  '/user/profile',
  '/courses/enrolled',
  '/dashboard',
  '/courses/:id/enroll', // Dynamic route for course enrollment
  '/courses/:id/progress', // Another example of a dynamic route
  '/stripe/create-checkout-session',
  // Add other protected routes here
];

export const isProtectedRoute = (url) => {
  // Remove query parameters from the URL
  const urlWithoutParams = url.split('?')[0];

  return PROTECTED_ROUTES.some(route => {
    // Check for exact matches
    if (route === urlWithoutParams) {
      return true;
    }
    
    // Check for pattern matches (dynamic routes)
    if (route.includes(':')) {
      const routeParts = route.split('/');
      const urlParts = urlWithoutParams.split('/');
      
      if (routeParts.length !== urlParts.length) {
        return false;
      }
      
      return routeParts.every((part, index) => {
        if (part.startsWith(':')) {
          return true; // This part is a parameter, so it matches anything
        }
        return part === urlParts[index];
      });
    }
    
    return false;
  });
};
